import { flatMap } from 'lodash';
import { ColumnId, ImportExportType } from '../../../gql/graphql';
import { CreateCandidate, CreateFormState } from '../../../services/create/createFormReducer';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../import-export/util/ImportExportSettingsGetters';
import { BookingFields, getFieldsToShow } from '../../manage/details/utils/detailsUtils';
import { transformCreateBookingFormStateToMinimalBooking } from '../../manage/details/utils/minimalBooking';

export const getCreateCandidateFormFields = (
  candidate: CreateCandidate,
  createFormState: CreateFormState,
): BookingFields => {
  const minimalBooking = transformCreateBookingFormStateToMinimalBooking(
    createFormState,
    candidate,
  );

  const columns = getFieldsToShow(
    minimalBooking,
    Boolean(createFormState.customer?.isCashCustomer),
  );

  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'importReference'),
    )?.type !== ImportExportType.EnvelopeId
  ) {
    columns.delete(ColumnId.ImportReference);
  }
  if (
    getImportExportSettingsFromMinimalBooking(
      minimalBooking,
      getImportExportSettingsFromRoute(minimalBooking.route, 'exportReference'),
    )?.type !== ImportExportType.EnvelopeId
  ) {
    columns.delete(ColumnId.ExportReference);
  }
  return columns;
};

export const getCreateFormFieldsToShow = (state: CreateFormState): BookingFields => {
  const createFieldCollection = flatMap(state.bookings, booking => [
    ...getCreateCandidateFormFields(booking, state),
  ]);

  return new Set(createFieldCollection);
};

export interface SpecificTableColumn {
  id: ColumnId;
  label: string;
  description?: string;
  onlyShowInExtendedMode: boolean;
  width: number;
}

export const specificTableColumns: SpecificTableColumn[] = [
  {
    id: ColumnId.VehicleRegNo,
    label: 'Vehicle Reg',
    onlyShowInExtendedMode: false,
    width: 130,
  },
  {
    id: ColumnId.TrailerRegNo,
    label: 'Trailer Reg',
    onlyShowInExtendedMode: false,
    width: 130,
  },
  {
    id: ColumnId.CustomerReference,
    label: 'Customer reference',
    onlyShowInExtendedMode: false,
    width: 160,
  },
  {
    id: ColumnId.HazardousGoods,
    label: 'Hazardous goods',
    onlyShowInExtendedMode: false,
    width: 150,
  },
  {
    id: ColumnId.LivestockTypeCode,
    label: 'Animals',
    onlyShowInExtendedMode: false,
    width: 165,
  },
  {
    id: ColumnId.Temperature,
    label: 'Temperature onboard (ºC)',
    onlyShowInExtendedMode: false,
    width: 180,
  },
  {
    id: ColumnId.CargoWeight,
    label: 'Cargo weight (kg)',
    onlyShowInExtendedMode: false,
    width: 130,
  },
  {
    id: ColumnId.LoadingPreferenceCode,
    label: 'Loading Pref.',
    onlyShowInExtendedMode: false,
    width: 120,
  },
  {
    id: ColumnId.NoOfDrivers,
    label: 'Drivers',
    onlyShowInExtendedMode: false,
    width: 76,
  },
  {
    id: ColumnId.CustomsClassification,
    label: 'UK customs procedure',
    onlyShowInExtendedMode: false,
    width: 224,
  },
  {
    id: ColumnId.SenderCountryCode,
    label: 'Sender country',
    onlyShowInExtendedMode: false,
    width: 120,
  },
  {
    id: ColumnId.ReceiverCountryCode,
    label: 'Receiver country',
    onlyShowInExtendedMode: false,
    width: 120,
  },
  {
    id: ColumnId.VehicleTypeCode,
    label: 'Vehicle type',
    onlyShowInExtendedMode: true,
    width: 120,
  },
  {
    id: ColumnId.Length,
    label: 'Length (m)',
    onlyShowInExtendedMode: true,
    width: 90,
  },
  {
    id: ColumnId.Width,
    label: 'Width (m)',
    onlyShowInExtendedMode: true,
    width: 90,
  },
  {
    id: ColumnId.Height,
    label: 'Height (m)',
    onlyShowInExtendedMode: true,
    width: 90,
  },
  {
    id: ColumnId.ExportReference,
    label: 'Export',
    onlyShowInExtendedMode: false,
    width: 160,
  },
  {
    id: ColumnId.ImportReference,
    label: 'Import',
    onlyShowInExtendedMode: false,
    width: 160,
  },
  {
    id: ColumnId.TradeWeight,
    label: 'Trade weight (kg)',
    onlyShowInExtendedMode: false,
    width: 130,
  },
];

export const getCreateColumnsToShow = (
  allColumns: SpecificTableColumn[],
  fieldsToShow: BookingFields,
): SpecificTableColumn[] => {
  if (allColumns.length === 0 || fieldsToShow.size === 0) {
    return [];
  }

  return allColumns.filter(column => fieldsToShow.has(column.id));
};

import { useCallback, useState } from 'react';

function getValueFromSessionStorage(key: string) {
  if (typeof sessionStorage === 'undefined') {
    return null;
  }
  const storedValue = sessionStorage.getItem(key) || 'null';
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(error);
  }

  return storedValue;
}

function saveValueToSessionStorage(key: string, value: any) {
  if (typeof sessionStorage === 'undefined') {
    return null;
  }

  return sessionStorage.setItem(key, JSON.stringify(value));
}

function initialize<S>(key: string, initialState: S) {
  const valueLoadedFromSessionStorage = getValueFromSessionStorage(key);
  if (valueLoadedFromSessionStorage === null) {
    return initialState;
  } else {
    return valueLoadedFromSessionStorage;
  }
}

export function useSessionStorageState<S>(
  key: string,
  initialState?: S | (() => S),
): [S, (v: S) => void, () => void] {
  const [value, __setValue] = useState(() => initialize(key, initialState));

  const setValue = useCallback(
    (newValue: S) => {
      __setValue(newValue);
      saveValueToSessionStorage(key, newValue);
    },
    [key],
  );

  const remove = useCallback(() => {
    sessionStorage.removeItem(key);
  }, [key]);

  return [value, setValue, remove];
}

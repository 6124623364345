export enum Colors {
  UI1 = '#202326' /* Heading text, almost --lhds-color-ui-800 */,
  UI2 = '#4c4f56' /* Normal text, almost --lhds-color-ui-700 */,
  UI3 = '#919296' /* Disabled, maybe --lhds-color-ui-500 */,
  UI4 = '#d1d2d7' /* Lines & Borders, almost --lhds-color-ui-400 */,
  UI5 = '#e4e5e9' /* Page background, --lhds-color-ui-300 */,
  UI6 = '#f2f3f5' /* almost --lhds-color-ui-200 */,
  UI8 = '#ffffff' /* White, --lhds-color-ui-50 */,
  Alert2 = '#ffbd42' /* maybe --lhds-color-orange-300 */,
  Alert4 = '#fdf2dd' /* almost --lhds-color-orange-50 */,
  Sos1 = '#d05353' /* Error, maybe --lhds-color-red-500 */,
  Sos4 = '#faebe9' /* maybe --lhds-color-red-50 */,
  Lush2 = '#60bd2f' /* --lhds-color-green-500 */,
  Lush4 = '#f7fcf5' /* almost --lhds-color-green-50 */,
  Freight2 = '#2378cd' /* Main blue, --lhds-color-blue-500 */,
  Freight3 = '#e2edf7' /* almost --lhds-color-blue-100 */,

  StenaSustainability = '#74b346',

  /* --- Project custom colors --- */

  UI3Alt = '#8c9196' /* maybe --lhds-color-ui-500 */,
  Freight2AltContrast = '#3494DD' /* Dashboard > Border color */,
  Night3 = '#3a4145',
}

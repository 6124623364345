import { InformationMessageType } from '../../../../common-ui/information-message/informationMessageUtils';
import { ApprovalConfig } from './approvalInfo';

export const resolveArrivalNotepadApprovalInfo = (
  arrivalNotepadApplied: boolean,
): ApprovalConfig | null => {
  if (arrivalNotepadApplied) {
    return {
      backgroundColor: null,
      descriptionText: "The unit is already on quay and the booking can't be updated.",
      headerText: 'Unit on quay',
      type: InformationMessageType.RegularInfo,
    };
  }
  return null;
};

import { Column } from '@stenajs-webui/core';
import { Banner, ResultListBanner } from '@stenajs-webui/elements';
import { useSelector } from 'react-redux';
import { Texts } from '../../../../common/texts';
import {
  getBannerVariantForMessageResultSeverity,
  getMessagesResult,
} from '../../../../common/update-information/utils/messagesResult';
import {
  getAllIntermodalCreateValidationErrors,
  getCreateIntermodalBookingCreateResult,
} from '../../../../services/intermodal/createIntermodalReducer';

const StatusBanner = () => {
  return <Banner variant={'info'} text={Texts.IntermodalCreate} />;
};

function ValidationErrorsBanners() {
  const allValidationErrors = useSelector(getAllIntermodalCreateValidationErrors);

  if (allValidationErrors.length === 0) {
    return null;
  }

  return (
    <ResultListBanner
      variant={'error'}
      bannerState={{
        headerText: 'Booking cannot be created',
        items: allValidationErrors.map(description => ({ text: description })),
      }}
    />
  );
}

function MessageResultBanners() {
  const createResult = useSelector(getCreateIntermodalBookingCreateResult);

  const messagesResult = createResult ? getMessagesResult(createResult) : null;

  if (!messagesResult) {
    return null;
  }

  const bannerVariant = getBannerVariantForMessageResultSeverity(messagesResult.severity);

  return (
    <ResultListBanner
      variant={bannerVariant}
      bannerState={{
        headerText: 'Booking was not created',
        items: messagesResult.messages.map(description => ({ text: description })),
      }}
    />
  );
}

export const CreateIntermodalInformationBanners = () => {
  return (
    <Column gap={1}>
      <StatusBanner />
      <ValidationErrorsBanners />
      <MessageResultBanners />
    </Column>
  );
};

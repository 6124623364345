import { FC } from 'react';
import { BookingDetailsModalWrapper } from '../../../common-ui/BookingDetailsModalWrapper';
import { BookingColumn, BookingColumnGrid } from '../../../common-ui/layout/BookingColumnGrid';
import { AllIntermodalVehicleTypesQueryRefProps } from '../../../services/intermodal/hooks/useAllIntermodalVehicleTypesAsOptions';
import { GoodsTypesQueryRefProps } from '../../../services/intermodal/hooks/useGoodsTypes';
import { CreateIntermodalDepartureSection } from './sections/CreateIntermodalDepartureSection';

import { CreateIntermodalConnectionsSection } from './sections/CreateIntermodalConnectionsSection';
import { CreateIntermodalDetailsSection } from './sections/CreateIntermodalDetailsSection';
import { BookingSection } from '../../manage/details/components/sections/BookingSection';
import { CreateIntermodalVehicleSection } from './sections/CreateIntermodalVehicleSection';
import { CreateIntermodalCargoSection } from './sections/CreateIntermodalCargoSection';
import { Box, Row, Space } from '@stenajs-webui/core';
import { CreateIntermodalInformationBanners } from '../common/banners/CreateIntermodalInformationBanners';
import { Cardy } from '@stenajs-webui/elements';

interface Props extends GoodsTypesQueryRefProps, AllIntermodalVehicleTypesQueryRefProps {}

export const IntermodalBookingCreate: FC<Props> = ({
  goodsTypesQueryRef,
  vehicleTypesQueryRef,
}) => (
  <BookingDetailsModalWrapper numColumns={5} mediumColumnWidth={288}>
    <Row display={['flex', null, null, null, 'none']} spacing={3}>
      <CreateIntermodalConnectionsSection compact />
    </Row>
    <CreateIntermodalInformationBanners />
    <Row flexDirection={['column', null, null, null, 'row']}>
      <Box width={340} display={['none', null, null, null, 'flex']}>
        <CreateIntermodalConnectionsSection />
      </Box>
      <Space num={2} />
      <Cardy flex={1}>
        <BookingColumnGrid>
          <BookingColumn>
            <BookingSection title={'Departure'}>
              <CreateIntermodalDepartureSection />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Vehicle'}>
              <CreateIntermodalVehicleSection vehicleTypesQueryRef={vehicleTypesQueryRef} />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Cargo'}>
              <CreateIntermodalCargoSection goodsTypesQueryRef={goodsTypesQueryRef} />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Details'}>
              <CreateIntermodalDetailsSection />
            </BookingSection>
          </BookingColumn>
        </BookingColumnGrid>
      </Cardy>
    </Row>
  </BookingDetailsModalWrapper>
);

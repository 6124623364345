export const encodeUserId = (userId: string): string => {
  return userId.replace('\\', '___');
};

export const decodeUserId = (userId: string): string => {
  return userId.replace('___', '\\');
};

export const resolveCustomsDocumentationParams = (
  bookingNo: number,
  sailingId: string,
  userId: string,
  route: string,
): string =>
  `?bookingNo=${bookingNo}&sailingId=${sailingId}&userId=${encodeURIComponent(
    userId,
  )}&routeCode=${route}`;

export const resolveCustomsDocumentationIframeURL = (
  bookingNo: number,
  sailingId: string,
  userId: string,
  route: string,
): string => {
  const customsDocumentationUrl = (window.FREIGHT_PORTAL_ENV || {}).CUSTOMS_DOCUMENTATION_URL;
  return `${customsDocumentationUrl}/${resolveCustomsDocumentationParams(
    bookingNo,
    sailingId,
    userId,
    route,
  )}`;
};

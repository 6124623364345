import { useQuery } from '@apollo/client';
import { Box } from '@stenajs-webui/core';
import { Banner, BannerVariant, FlatButton, stenaTimes } from '@stenajs-webui/elements';
import { graphql } from '../../gql';
import { SeverityLevel } from '../../gql/graphql';
import { TEN_MINUTES_IN_MS } from '../../services/frontend-out-of-date/frontendOutOfDateSaga';
import { trackEvent } from '../tracking/trackerService';
import { useSessionStorageState } from './useSessionStorageState';

const maintenanceMessageQuery = graphql(/* GraphQL */ `
  query MaintenanceMessageQuery {
    viewer {
      maintenanceMessages {
        id
        title
        description
        dismissible
        severity
      }
    }
  }
`);

function getVariantFromMessageSeverity(message: SeverityLevel): BannerVariant {
  switch (message) {
    case SeverityLevel.Info:
      return 'info';
    case SeverityLevel.Error:
      return 'error';
    case SeverityLevel.Warning:
      return 'warning';
  }
}

export const MaintenanceMessageBanner = () => {
  const [hiddenIds, setHiddenIds] = useSessionStorageState<string[]>('MAINTENANCE_MESSAGE_IDS', []);
  const { data } = useQuery(maintenanceMessageQuery, {
    pollInterval: TEN_MINUTES_IN_MS,
  });

  const messages =
    data?.viewer.maintenanceMessages.filter(message => !hiddenIds.includes(message.id)) ?? [];

  if (messages.length < 1) {
    return null;
  }

  return (
    <Box style={{ marginLeft: 'var(--nav-menu-width)' }}>
      {messages.map(message => {
        const trackerLabel = message.title ?? message.description;

        return (
          <Banner
            key={message.id}
            variant={getVariantFromMessageSeverity(message.severity)}
            headerText={message.title ?? undefined}
            text={message.description}
            contentRight={
              message.dismissible ? (
                <FlatButton
                  leftIcon={stenaTimes}
                  onClick={() => {
                    setHiddenIds([...hiddenIds, message.id]);
                    trackEvent({
                      category: 'General',
                      action: 'Dismiss maintenance message',
                      label: trackerLabel,
                    });
                  }}
                />
              ) : null
            }
          />
        );
      })}
    </Box>
  );
};

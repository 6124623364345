import { FC } from 'react';
import { ModalFooterBase } from '../../../common-ui/ModalFooterBase';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { useEditable } from '../../../common/modal/EditableContext';
import { IntermodalCancelButton } from './IntermodalCancelButton';
import { getUpdateIntermodalFormIsEdited } from '../../../services/intermodal/updateIntermodalReducer';
import { useUpdateIntermodalBooking } from '../../../services/intermodal/hooks/useUpdateIntermodalBooking';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '@stenajs-webui/elements';

interface IntermodalDetailsFooterProps {
  booking: DetailedIntermodalBooking;
  refetch: () => void;
}

export const UpdateIntermodalFooter: FC<IntermodalDetailsFooterProps> = ({ booking, refetch }) => {
  const shouldShowFooter = useEditable();
  const edited = useSelector(getUpdateIntermodalFormIsEdited);
  const { submit, loading } = useUpdateIntermodalBooking(refetch);
  if (!shouldShowFooter) {
    return null;
  }

  return (
    <ModalFooterBase
      leftContent={<IntermodalCancelButton booking={booking} />}
      rightContent={
        <PrimaryButton
          label={'Save changes'}
          className={'t_intermodal_save_button'}
          loadingLabel={'Saving...'}
          disabled={!edited}
          loading={loading}
          onClick={() => {
            submit();
          }}
        />
      }
    />
  );
};

import { FC } from 'react';
import { BookingDetailsModalWrapper } from '../../../common-ui/BookingDetailsModalWrapper';
import { BookingColumn, BookingColumnGrid } from '../../../common-ui/layout/BookingColumnGrid';
import { DetailedIntermodalBooking } from '../../../common/graphql/fragments/gql/IntermodalBooking';
import { AllIntermodalVehicleTypesQueryRefProps } from '../../../services/intermodal/hooks/useAllIntermodalVehicleTypesAsOptions';
import { GoodsTypesQueryRefProps } from '../../../services/intermodal/hooks/useGoodsTypes';
import { BookingSection } from '../../manage/details/components/sections/BookingSection';
import { UpdateIntermodalInformationBanners } from '../common/banners/UpdateIntermodalInformationBanners';
import {
  CompactIntermodalJourneyInfo,
  IntermodalJourneyInfo,
} from '../common/IntermodalJourneyInfo';
import { UpdateIntermodalCargoSection } from './sections/UpdateIntermodalCargoSection';
import { UpdateIntermodalDepartureSection } from './sections/UpdateIntermodalDepartureSection';
import { UpdateIntermodalDetailsSection } from './sections/UpdateIntermodalDetailsSection';
import { UpdateIntermodalVehicleSection } from './sections/UpdateIntermodalVehicleSection';
import { Box, Row, Space } from '@stenajs-webui/core';
import { Cardy } from '@stenajs-webui/elements';

interface IntermodalBookingDetailsProps
  extends GoodsTypesQueryRefProps,
    AllIntermodalVehicleTypesQueryRefProps {
  booking: DetailedIntermodalBooking;
}

export const IntermodalBookingUpdate: FC<IntermodalBookingDetailsProps> = ({
  booking,
  goodsTypesQueryRef,
  vehicleTypesQueryRef,
}) => (
  <BookingDetailsModalWrapper numColumns={5} mediumColumnWidth={288}>
    <Row display={['flex', null, null, null, 'none']} spacing={3}>
      <CompactIntermodalJourneyInfo legs={booking.journey.legs} />
    </Row>
    <UpdateIntermodalInformationBanners booking={booking} />
    <Row flexDirection={['column', null, null, null, 'row']}>
      <Box width={340} display={['none', null, null, null, 'flex']}>
        <IntermodalJourneyInfo legs={booking.journey.legs} />
      </Box>
      <Space num={2} />
      <Cardy flex={1}>
        <BookingColumnGrid>
          <BookingColumn>
            <BookingSection title={'Departure'}>
              <UpdateIntermodalDepartureSection alternativeRoutes={booking.alternativeRoutes} />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Vehicle'}>
              <UpdateIntermodalVehicleSection
                craneableStatus={booking.craneableStatus}
                vehicleTypesQueryRef={vehicleTypesQueryRef}
              />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Cargo'}>
              <UpdateIntermodalCargoSection
                hazardousGoods={booking.hazardousGoods}
                goodsTypesQueryRef={goodsTypesQueryRef}
              />
            </BookingSection>
          </BookingColumn>
          <BookingColumn>
            <BookingSection title={'Details'}>
              <UpdateIntermodalDetailsSection
                partnerBookings={booking.partnerBookings ?? []}
                dropOffRef={booking.dropOffReference}
                pickUpRef={booking.pickUpReference}
              />
            </BookingSection>
          </BookingColumn>
        </BookingColumnGrid>
      </Cardy>
    </Row>
  </BookingDetailsModalWrapper>
);

import { ReactNode } from 'react';
import { getCSSVariable } from '../common/utils/cssUtils';
import { sectionIndent } from '../use-cases/manage/details/components/sections/BookingSection';
import { Column } from '@stenajs-webui/core';
import { globalContentWrapperIndent } from '../globalStyles';

interface Props {
  children: ReactNode;
  numColumns: number;
  largeColumnWidth?: number;
  mediumColumnWidth?: number;
}

export const BookingDetailsModalWrapper = ({
  children,
  numColumns,
  largeColumnWidth = 320,
  mediumColumnWidth = largeColumnWidth,
}: Props) => {
  const swuiMetricsSpacing = parseInt(getCSSVariable('--swui-metrics-spacing'));
  const sectionTotalIndent = 2 * sectionIndent * swuiMetricsSpacing;
  const sectionWidthLarge = largeColumnWidth + sectionTotalIndent;
  const sectionWidthMedium = mediumColumnWidth + sectionTotalIndent;

  const width768 = 2 * sectionWidthLarge + sectionTotalIndent;
  const width1280 = numColumns * sectionWidthMedium;

  const modalWidth = ['100%', 448, width768, null, width1280];

  return (
    <Column
      indent={globalContentWrapperIndent}
      spacing={3}
      minHeight={560}
      width={modalWidth}
      maxWidth={'100%'}
      gap={2}
    >
      {children}
    </Column>
  );
};

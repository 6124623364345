import { ColumnId } from '../../../../../gql/graphql';
import { BasicBooking } from '../../../../../common/graphql/fragments/gql/BasicBooking';
import { HiddenFieldGate } from '../../../../../common-ui/HiddenFieldGate';
import { BookingFields, isHidden } from '../../utils/detailsUtils';
import { DetailsFieldCell } from '../DetailsFieldCell';
import { CustomsClassificationField } from '../fields/CustomsClassificationField';
import { Column } from '@stenajs-webui/core';
import { Label } from '@stenajs-webui/elements';
import { ImportExportField } from '../fields/ImportExportField';
import { FC } from 'react';
import { BookingSection } from './BookingSection';

interface Props {
  fieldsToShow: BookingFields;
  fieldsToEnable: BookingFields;
  disabled: boolean;
  booking: BasicBooking;
}

export const ImportExportDetailsSection: FC<Props> = ({
  fieldsToEnable,
  booking,
  fieldsToShow,
  disabled,
}) => {
  const isDisabled = (field: ColumnId) => disabled || isHidden(fieldsToEnable, field);
  return (
    <BookingSection title={'Import/Export'}>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.CustomsClassification)}>
        <DetailsFieldCell
          label="UK customs procedure"
          field={
            <CustomsClassificationField disabled={isDisabled(ColumnId.CustomsClassification)} />
          }
        />
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.ExportReference)}>
        <Column>
          <Label text={'Export'} />
          <ImportExportField
            name={'exportReference'}
            booking={booking}
            disabled={isDisabled(ColumnId.ExportReference)}
          />
        </Column>
      </HiddenFieldGate>
      <HiddenFieldGate hidden={isHidden(fieldsToShow, ColumnId.ImportReference)}>
        <Column>
          <Label text={'Import'} />
          <ImportExportField
            name={'importReference'}
            booking={booking}
            disabled={isDisabled(ColumnId.ImportReference)}
          />
        </Column>
      </HiddenFieldGate>
    </BookingSection>
  );
};

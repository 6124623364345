import { put, select } from 'redux-saga/effects';
import { UpdatePageSettings2Input } from '../../../gql/graphql';
import { getColumnFilter } from '../../columns-filter/columnsFilterReducer';
import { requestSavePageSettings } from '../pageSettingsActions';
import { columnIdEntries } from './utils/columnIdEntries';

export function* updateColumnsEnabledPageSettings() {
  const columnFilter: ReturnType<typeof getColumnFilter> = yield select(getColumnFilter);

  const input: UpdatePageSettings2Input = {
    column: {
      enabled: columnIdEntries(columnFilter.enabled).map(([columnId, enabled]) => ({
        columnId,
        enabled,
      })),
    },
  };

  yield put(requestSavePageSettings(input));
}

export function* updateColumnsOrderPageSettings() {
  const columnFilter: ReturnType<typeof getColumnFilter> = yield select(getColumnFilter);

  const input: UpdatePageSettings2Input = {
    column: {
      order: columnFilter.order,
    },
  };

  yield put(requestSavePageSettings(input));
}

export function* updateColumnsWidthPageSettings() {
  const columnFilter: ReturnType<typeof getColumnFilter> = yield select(getColumnFilter);

  const input: UpdatePageSettings2Input = {
    column: {
      width: columnIdEntries(columnFilter.width).map(([columnId, width]) => ({
        columnId,
        width,
      })),
    },
  };

  yield put(requestSavePageSettings(input));
}

export function* updateColumnsWithDefaultSettings() {
  const columnFilter: ReturnType<typeof getColumnFilter> = yield select(getColumnFilter);

  const input: UpdatePageSettings2Input = {
    column: {
      order: columnFilter.order,
      enabled: columnIdEntries(columnFilter.enabled).map(([columnId, enabled]) => ({
        columnId,
        enabled,
      })),
    },
  };

  yield put(requestSavePageSettings(input));
}

import { Row } from '@stenajs-webui/core';
import { Link, TextInputButtonSize } from '@stenajs-webui/elements';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import {
  BasicBooking,
  BasicBooking_exportReference_status,
  BasicBooking_importReference_status,
} from '../../common/graphql/fragments/gql/BasicBooking';
import { resolveCustomsDocumentationIframeURL } from '../../common/utils/customsEnsUtils';
import { ImportExportStatusCode } from '../../gql/graphql';
import { getUserNameWithPrefix } from '../../services/authentication/authReducer';
import { ImportExportStatusCellIconButton } from '../../use-cases/import-export/components/ImportExportStatusCellIconButton';

interface EnsContentProps {
  booking: BasicBooking;
  importExportStatus?:
    | BasicBooking_importReference_status
    | BasicBooking_exportReference_status
    | null;
  disabled: boolean;
  onRefreshIconClick?: () => Promise<void>;
  popoverVisible: boolean;
  onOpenPopover: () => void;
  onRequestClosePopover: () => void;
  size?: TextInputButtonSize;
}

export const EnsContent = forwardRef<HTMLAnchorElement, EnsContentProps>(
  (
    {
      booking,
      importExportStatus,
      disabled,
      onRefreshIconClick,
      popoverVisible,
      onOpenPopover,
      onRequestClosePopover,
      size,
    },
    ref,
  ) => {
    const userName = useSelector(getUserNameWithPrefix);

    return (
      <Row>
        <Row indent={0.5} alignItems={'center'}>
          <ImportExportStatusCellIconButton
            status={importExportStatus}
            bookingNo={booking.bookingNo}
            onRefreshIconClick={onRefreshIconClick}
            visible={popoverVisible}
            onOpen={onOpenPopover}
            size={size}
            onRequestClose={onRequestClosePopover}
          />
        </Row>
        <Row indent={0.5} alignItems={'center'}>
          <Link
            target={'_blank'}
            href={resolveCustomsDocumentationIframeURL(
              booking.bookingNo,
              booking.sailing.id,
              userName,
              booking.sailing.route.code,
            )}
            style={{ whiteSpace: 'nowrap' }}
            disabled={disabled}
          >
            <span ref={ref}>
              {importExportStatus?.statusCode === ImportExportStatusCode.Success
                ? 'ENS Confirmed'
                : 'Declare ENS'}
            </span>
          </Link>
        </Row>
      </Row>
    );
  },
);

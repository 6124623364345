import { Row, Space, Text, useBoolean } from '@stenajs-webui/core';
import { TextInput } from '@stenajs-webui/forms';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { EnsContent } from '../../../../../common-ui/import-export/EnsContent';
import { fetchBooking } from '../../../../../common/api';
import { BasicBooking } from '../../../../../common/graphql/fragments/gql/BasicBooking';
import { getImportExportStatus } from '../../../../../common/import-export/getImportExportStatus';
import { trackEvent } from '../../../../../common/tracking/trackerService';
import { useBookingFormInstanceId } from '../../../../../common/update-information/BookingFormInstanceIdProvider';
import { useUpdateField } from '../../../../../common/update-information/hooks/useUpdateField';
import { useUpdateFormState } from '../../../../../common/update-information/hooks/useUpdateFormState';
import { transformToUpdateBookingFormState } from '../../../../../common/update-information/utils/transformToUpdateBookingFormState';
import { exhaustSwitchCaseElseNull } from '../../../../../common/utils/ExhaustSwitchCase';
import { ImportExportStatusCode, ImportExportType } from '../../../../../gql/graphql';
import { initializeUpdateForm } from '../../../../../services/update-form/updateFormActions';
import { CustomsLinkPopupContent } from '../../../../customslink/components/popup/CustomsLinkPopupContent';
import { CUSTOMS_LINK_LABEL_SCAC } from '../../../../customslink/utils/constants';
import {
  getShowCustomsLinkForEnvelopeId,
  getShowCustomsLinkForScac,
} from '../../../../customslink/utils/showCustomsLinkUtil';
import { ImportExportStatusCellIconButton } from '../../../../import-export/components/ImportExportStatusCellIconButton';
import {
  getImportExportSettingsFromMinimalBooking,
  getImportExportSettingsFromRoute,
} from '../../../../import-export/util/ImportExportSettingsGetters';
import {
  transformUpdateFieldToTrackerAction,
  useTrackingTextInput,
} from '../../../../simple-create/hooks/UseTrackingTextInput';
import { getEnvelopeIdPlaceholder } from '../../../grid/components/grid-cells/import-export/utils/envelopeIdUtil';
import { useUpdateFormStateMinimalBooking } from '../../utils/minimalBooking';

interface ImportExportFieldProps {
  name: 'importReference' | 'exportReference';
  booking: BasicBooking;
  disabled?: boolean;
}

export const ImportExportField: FC<ImportExportFieldProps> = ({ booking, name, disabled }) => {
  const { edited, submitting } = useUpdateFormState(useBookingFormInstanceId());
  const { onBlur, onFocus } = useTrackingTextInput(
    transformUpdateFieldToTrackerAction(name),
    'Details',
  );

  const status = edited ? null : booking[name].status;

  const dispatch = useDispatch();

  const onRefreshBooking = async () => {
    trackEvent({
      category: 'Details',
      action: 'Refresh import export status',
      label: ImportExportStatusCode.Refresh,
    });
    const bookingNo = booking.bookingNo;
    const fetchBookingResult = await fetchBooking(bookingNo);
    const newBooking = fetchBookingResult.success ? fetchBookingResult.value : null;
    if (newBooking) {
      const formState = transformToUpdateBookingFormState(newBooking);
      dispatch(initializeUpdateForm({ bookingNo, formId: 'Details' }, formState, newBooking));
    }
  };

  const minimalBooking = useUpdateFormStateMinimalBooking();

  const importExportSettings = getImportExportSettingsFromMinimalBooking(
    minimalBooking,
    getImportExportSettingsFromRoute(minimalBooking?.route, name),
  );

  const { editedValue, initialValue, onChange } = useUpdateField<
    'importReference' | 'exportReference'
  >(name);

  const [visible, openPopover, closePopover] = useBoolean(false);

  const trackOpenPopover = () => {
    trackEvent({
      category: 'Details',
      action: 'Brexit icon clicked',
      label: importExportSettings ? importExportSettings.type : '',
    });
    openPopover();
  };

  if (
    name === 'importReference' &&
    minimalBooking?.route?.useCustomsClassification &&
    minimalBooking?.customsClassificationCode == null
  ) {
    return (
      <TextInput
        disabled
        disableContentPaddingLeft
        placeholder={'Select customs proc.'}
        width={'100%'}
        value={editedValue}
      />
    );
  }

  if (!importExportSettings || importExportSettings.type === ImportExportType.Empty) {
    return null;
  }

  if (importExportSettings.type === ImportExportType.Scac) {
    const statusCode =
      name === 'importReference'
        ? booking.importReference.status?.statusCode
        : booking.exportReference.status?.statusCode;
    return (
      <Row alignItems={'center'}>
        <ImportExportStatusCellIconButton
          status={status}
          bookingNo={booking.bookingNo}
          showPopupRefreshStatusButton={statusCode === ImportExportStatusCode.Refresh}
          visible={visible}
          onOpen={trackOpenPopover}
          onRequestClose={closePopover}
        >
          {getShowCustomsLinkForScac(booking) && (
            <CustomsLinkPopupContent
              onRequestClose={closePopover}
              booking={booking}
              label={CUSTOMS_LINK_LABEL_SCAC}
              trackerCategory={'Details'}
            />
          )}
        </ImportExportStatusCellIconButton>
        <Space num={1} />
        <Text>{name === 'importReference' ? booking.importScacId : booking.exportScacId}</Text>
      </Row>
    );
  }

  if (importExportSettings.type === ImportExportType.Ens) {
    return (
      <EnsContent
        booking={booking}
        importExportStatus={status}
        disabled={edited || submitting}
        popoverVisible={visible}
        onOpenPopover={trackOpenPopover}
        onRequestClosePopover={closePopover}
      />
    );
  }

  if (importExportSettings.type === ImportExportType.EnvelopeId) {
    const { hideStatusIcon } = getImportExportStatus({
      editedValue,
      initialValue,
    });

    return (
      <TextInput
        disabled={disabled}
        disableContentPaddingLeft
        placeholder={getEnvelopeIdPlaceholder(importExportSettings)}
        contentLeft={
          !hideStatusIcon ? (
            <div style={{ marginLeft: '3px' }}>
              <ImportExportStatusCellIconButton
                onRefreshIconClick={onRefreshBooking}
                bookingNo={booking.bookingNo}
                status={status}
                visible={visible}
                onOpen={trackOpenPopover}
                onRequestClose={closePopover}
              >
                {getShowCustomsLinkForEnvelopeId(
                  booking,
                  importExportSettings.name,
                  editedValue,
                ) && (
                  <CustomsLinkPopupContent
                    onRequestClose={closePopover}
                    booking={booking}
                    label={importExportSettings.name}
                    trackerCategory={'Details'}
                  />
                )}
              </ImportExportStatusCellIconButton>
            </div>
          ) : undefined
        }
        width={'100%'}
        onValueChange={onChange}
        value={editedValue}
        style={{
          fontStyle: editedValue !== initialValue ? 'italic' : 'normal',
          fontWeight: editedValue !== initialValue ? 'bold' : 'normal',
        }}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    );
  }

  return exhaustSwitchCaseElseNull(importExportSettings.type);
};

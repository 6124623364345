import { Tag, TagProps, TagSize } from '@stenajs-webui/elements';
import { FC } from 'react';
import {
  BasicBooking_bookingQuayStatus,
  BasicBooking_bookingStatus,
} from '../common/graphql/fragments/gql/BasicBooking';
import { IntermodalBooking_status } from '../common/graphql/fragments/gql/IntermodalBooking';
import {
  resolveIntermodalStatusInformation,
  resolveStatusInformation,
} from '../common/utils/bookingStatus';
import { useGridTagSize } from '../services/grid/hooks/useGridHeight';

export type BookingTagStatus = Pick<BasicBooking_bookingStatus, 'status' | 'label'>;
export type BookingTagQuayStatus = Pick<BasicBooking_bookingQuayStatus, 'status' | 'label'> | null;

interface BookingStatusTagProps {
  status: BookingTagStatus;
  quayStatus: BookingTagQuayStatus;
  size?: TagSize;
}

interface CommonTagProps extends Omit<TagProps, 'variant'> {
  backgroundColor?: string;
}

const CommonStatusTag = ({ style, backgroundColor, ...props }: CommonTagProps) => (
  <Tag style={{ backgroundColor, ...style }} {...props} />
);

export const BookingStatusTag: FC<BookingStatusTagProps> = ({ status, quayStatus, size }) => {
  const statusProps = resolveStatusInformation(status, quayStatus);

  return <CommonStatusTag {...statusProps} size={size} />;
};

export const GridBookingStatusTag: FC<Omit<BookingStatusTagProps, 'size'>> = ({
  status,
  quayStatus,
}) => {
  const size = useGridTagSize();

  const statusProps = resolveStatusInformation(status, quayStatus);

  return <CommonStatusTag {...statusProps} size={size} />;
};

interface IntermodalBookingStatusTagProps {
  status: IntermodalBooking_status;
  size?: TagSize;
}

export const IntermodalBookingStatusTag: FC<IntermodalBookingStatusTagProps> = ({
  status,
  size,
}) => {
  const statusProps = resolveIntermodalStatusInformation(status.status);

  return <CommonStatusTag {...statusProps} size={size} label={status.label} />;
};

export const GridIntermodalBookingStatusTag: FC<Omit<IntermodalBookingStatusTagProps, 'size'>> = ({
  status,
}) => {
  const size = useGridTagSize();

  return <IntermodalBookingStatusTag status={status} size={size} />;
};

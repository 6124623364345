import styled from '@emotion/styled';
import { Box } from '@stenajs-webui/core';
import { breakpoints } from '../../themes/defaultTheme';

export const BookingColumn = styled(Box)``;

export const BookingColumnGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;

  .show-only-xl {
    display: none;
  }

  @media screen and (min-width: ${breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media print, screen and (min-width: ${breakpoints.xl}) {
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-template-columns: none;

    .show-only-xl {
      display: flex;
    }
  }
`;

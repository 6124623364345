import { Box } from '@stenajs-webui/core';
import { PrimaryButton } from '@stenajs-webui/elements';
import { PayButton, SeePaymentLink } from '../../../../common-ui/payment/PayButton';
import { BasicBooking } from '../../../../common/graphql/fragments/gql/BasicBooking';
import { useShowCalculatePrice } from '../../../../common/pricing/useShowCalculatePrice';
import { transformToUpdateBookingFormState } from '../../../../common/update-information/utils/transformToUpdateBookingFormState';
import { isBookingEditable } from '../../../../services/update-form/utils/bookingUtils';
import { CalculatePriceForDetails } from '../pricing/CalculatePriceForDetails';
import { bookingHasPayments, bookingIsPayable } from '../utils/showPaymentUtil';
import { BookingCancelButton } from './BookingCancelButton';
import { trackEvent } from '../../../../common/tracking/trackerService';
import { FC } from 'react';
import { useHasPermission } from '../../../../common/statistics/useHasPermission';
import { Permission } from '../../../../services/authentication/utils/authUtils';
import { ModalFooterBase } from '../../../../common-ui/ModalFooterBase';

export interface ModalFooterProps {
  booking: BasicBooking;
  onCancelFail: () => void;
  onStartEdit?: () => void;
}

const trackerCategory = 'Details';

export const shouldShowModalFooterView = (booking: BasicBooking) =>
  bookingHasPayments(booking) || isBookingEditable(booking) || booking.cancelable;

export const ModalFooterView: FC<ModalFooterProps> = ({ booking, onCancelFail, onStartEdit }) => {
  const showCalculatePrice = useShowCalculatePrice(
    booking.customer.isCashCustomer,
    booking.bookingStatus.status,
  );
  const showSeePaymentLink = bookingHasPayments(booking);
  const showPayButton = bookingIsPayable(booking);
  const getHasPermission = useHasPermission();
  const hasBookingWritePermission = getHasPermission(Permission.EditBookings);
  const showEditButton = hasBookingWritePermission;
  const showCancelButton = hasBookingWritePermission && booking.cancelable;

  const shouldShowFooter = shouldShowModalFooterView(booking);

  if (!shouldShowFooter) {
    return null;
  }

  const onClickEdit = () => {
    if (onStartEdit) {
      trackEvent({ category: 'Details', action: 'Edit booking' });
      onStartEdit();
    }
  };

  return (
    <ModalFooterBase
      leftContent={
        showCancelButton && <BookingCancelButton booking={booking} onFail={onCancelFail} />
      }
      rightContent={
        <Box flexDirection={['column', null, 'row']} gap={1}>
          {showSeePaymentLink && (
            <SeePaymentLink bookingNo={booking.bookingNo} trackerCategory={trackerCategory} />
          )}
          {isBookingEditable(booking) && (
            <>
              {showCalculatePrice && (
                <CalculatePriceForDetails
                  formState={transformToUpdateBookingFormState(booking)}
                  placement={'top'}
                />
              )}
              {showPayButton && (
                <PayButton
                  secondary
                  bookingNo={booking.bookingNo}
                  trackerCategory={trackerCategory}
                />
              )}
              {showEditButton && (
                <PrimaryButton
                  label={'Edit booking'}
                  onClick={onClickEdit}
                  title={'Edit booking'}
                />
              )}
            </>
          )}
        </Box>
      }
    />
  );
};

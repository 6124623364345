import { Texts } from '../../../../common/texts';

export const resolveMiscInformation = (
  hasLivestock: boolean,
  hasUnsavedHazardousGoods: boolean,
) => {
  if (hasLivestock) {
    return Texts.AnimalsInformation;
  } else if (hasUnsavedHazardousGoods) {
    return Texts.HazardousGoodsRequested;
  } else return null;
};

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  stenaArrowDown,
  stenaArrowRight,
  stenaCheck,
  stenaClock,
  stenaDotFilled,
  stenaExclamationTriangle,
  stenaSailingOnRoute,
  stenaSailingTrailerPu,
  stenaSailingTrain,
  stenaSailingTruckSide,
  stenaStatusBlock,
  stenaStatusCanceled,
  stenaStatusConfirmed,
  stenaStatusDone,
  stenaStatusInProgress,
  stenaStatusInProgressAlt,
  stenaStatusNoShow,
} from '@stenajs-webui/elements';
import { BookingTagQuayStatus, BookingTagStatus } from '../../common-ui/BookingStatusTag';
import {
  BookingQuayStatus,
  BookingStatus,
  BookingStatus as GQLBookingStatus,
  IntermodalBookingStatus,
} from '../../gql/graphql';
import { cssColor } from '@stenajs-webui/theme';

const TAG_BACKGROUND_COLOR_INFO = cssColor('--lhds-color-blue-100');
const TAG_BACKGROUND_COLOR_PASSIVE = cssColor('--silver-lighter');
const TAG_BACKGROUND_COLOR_WHITE = '#fff';
const TAG_BACKGROUND_COLOR_SUCCESS = cssColor('--lhds-color-green-100');
const TAG_BACKGROUND_COLOR_SUCCESS_STRONG = cssColor('--lhds-color-green-200');
const TAG_BACKGROUND_COLOR_WARNING = cssColor('--lhds-color-orange-100');
const TAG_BACKGROUND_COLOR_ERROR = cssColor('--lhds-color-red-100');

export interface BookingStatusInformation {
  icon: IconDefinition;
  label: string;
  backgroundColor?: string;
}

export const resolveFullStatusText = (
  status: BookingTagStatus,
  quayStatus?: BookingTagQuayStatus | null,
): string => {
  if (status.status === BookingStatus.CheckedInWaitlist) {
    return 'Waitlist - Checked in';
  }
  if (status.status === BookingStatus.Shipped && quayStatus) {
    return quayStatus.label;
  }
  if (status.status === BookingStatus.CheckedIn && quayStatus) {
    return quayStatus.label;
  }
  return status.label;
};

const resolveStatusTagAppearance = (
  status: GQLBookingStatus | null,
  quayStatus?: BookingQuayStatus | null,
): Omit<BookingStatusInformation, 'label'> => {
  switch (status) {
    case GQLBookingStatus.BlockBooking:
      return {
        icon: stenaStatusBlock,
        backgroundColor: TAG_BACKGROUND_COLOR_INFO,
      };
    case GQLBookingStatus.Cancelled:
      return {
        icon: stenaStatusCanceled,
        backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE,
      };
    case GQLBookingStatus.CheckedIn:
      if (quayStatus === BookingQuayStatus.Loaded) {
        return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
      }
      return {
        icon: stenaCheck,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS_STRONG,
      };
    case GQLBookingStatus.CheckedInWaitlist:
      return {
        icon: stenaStatusInProgress,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case GQLBookingStatus.Confirmed:
      return {
        icon: stenaStatusConfirmed,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS,
      };
    case GQLBookingStatus.NoShow:
      return {
        icon: stenaStatusNoShow,
        backgroundColor: TAG_BACKGROUND_COLOR_ERROR,
      };
    case GQLBookingStatus.Waitlist:
      return {
        icon: stenaStatusInProgressAlt,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case GQLBookingStatus.Shipped: {
      switch (quayStatus) {
        case BookingQuayStatus.ReadyForPickup:
          return {
            icon: stenaSailingTruckSide,
            backgroundColor: TAG_BACKGROUND_COLOR_INFO,
          };
        case BookingQuayStatus.Unloaded:
          return {
            icon: stenaArrowDown,
            backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
          };
        case BookingQuayStatus.OnHold:
          return {
            icon: stenaExclamationTriangle,
            backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
          };
        case BookingQuayStatus.GateExit:
          return {
            icon: stenaArrowRight,
            backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS_STRONG,
          };
        default:
          return {
            icon: stenaSailingOnRoute,
            backgroundColor: TAG_BACKGROUND_COLOR_WHITE,
          };
      }
    }
    default:
      return { icon: stenaDotFilled, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
  }
};

export const resolveStatusInformation = (
  status: BookingTagStatus,
  quayStatus?: BookingTagQuayStatus,
): BookingStatusInformation => {
  const label = resolveFullStatusText(status, quayStatus);
  const { icon, backgroundColor } = resolveStatusTagAppearance(status.status, quayStatus?.status);

  return { label, icon, backgroundColor };
};

export function resolveIntermodalStatusInformation(
  status: IntermodalBookingStatus | null,
): Omit<BookingStatusInformation, 'label'> {
  switch (status) {
    case IntermodalBookingStatus.GateExit:
      return {
        icon: stenaArrowRight,
        backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS,
      };
    case IntermodalBookingStatus.ReadyForPickup:
      return {
        icon: stenaSailingTrailerPu,
        backgroundColor: TAG_BACKGROUND_COLOR_INFO,
      };
    case IntermodalBookingStatus.EtaPassed:
      return { icon: stenaClock, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case IntermodalBookingStatus.OnRoute:
      return { icon: stenaSailingTrain, backgroundColor: TAG_BACKGROUND_COLOR_WHITE };
    case IntermodalBookingStatus.Confirmed:
    case IntermodalBookingStatus.Arrived:
      return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_SUCCESS };
    case IntermodalBookingStatus.Cancelled:
      return { icon: stenaStatusCanceled, backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE };
    case IntermodalBookingStatus.Waitlist:
      return {
        icon: stenaStatusInProgressAlt,
        backgroundColor: TAG_BACKGROUND_COLOR_WARNING,
      };
    case IntermodalBookingStatus.Updated:
    case IntermodalBookingStatus.InProgress:
      return { icon: stenaStatusInProgress, backgroundColor: TAG_BACKGROUND_COLOR_WARNING };
    default:
      return { icon: stenaStatusDone, backgroundColor: TAG_BACKGROUND_COLOR_PASSIVE };
  }
}

import _, { fill } from 'lodash';
import { VehicleType } from '../../../common/graphql/fragments/gql/VehicleType';
import { animalDropdownNoOption } from '../../../common/graphql/useAnimalTypesOptions';
import { customsClassificationDropdownEmptyOption } from '../../../common/graphql/useCustomsClassificationsOptions';
import { loadingPreferenceDropdownNoOption } from '../../../common/graphql/useLoadingPreferenceOptions';
import { formatRegistrationNumberString } from '../../../common/utils/stringUtil';
import { ColumnId, CreateBookingInput } from '../../../gql/graphql';
import { createCreateCandidateMock } from '../../../services/create/_mocks/createCandidateMock';
import { CreateCandidate, CreateFormState } from '../../../services/create/createFormReducer';
import { getNumberOfDrivers } from '../../../services/create/utils/transformCreateBookingResults';
import { isVisible } from '../../manage/details/utils/detailsUtils';
import { getCreateFormFieldsToShow } from './createFormFieldsUtil';

export const getInitialCreateCandidate = (
  vehicleType: VehicleType | null,
  height: number | undefined,
  length: number | undefined,
  width: number | undefined,
  tradeWeight: number | undefined,
): CreateCandidate => {
  return {
    cargoWeight: undefined,
    customerRef: '',
    customsClassification: customsClassificationDropdownEmptyOption.data,
    hasAnimals: false,
    hasCargoWeight: false,
    hasElectricalPlugin: false,
    hasHazardousGoods: false,
    height,
    length,
    livestockType: animalDropdownNoOption.data,
    loadingListMessage: '',
    loadingPreference: loadingPreferenceDropdownNoOption.data,
    meta: {
      errors: [],
      warnings: [],
    },
    importReference: '',
    exportReference: '',
    numberOfDrivers: getNumberOfDrivers(vehicleType),
    pluginTemperature: '',
    receiverCountry: undefined,
    registrationNumberAlert: null,
    senderCountry: undefined,
    tradeWeight,
    trailerReg: '',
    vehicleReg: '',
    vehicleType,
    width,
  };
};

export const ensureAllBookingCandidatesHasValues = (
  bookings: CreateCandidate[],
  numberOfCandidates: number,
): CreateCandidate[] => {
  const firstNoConflictingBooking = findCandidateWithoutRegNumberAlert(bookings) || bookings[0];

  const takenCandidate = _.take(bookings, numberOfCandidates);

  const candidates = takenCandidate.map(candidate =>
    !!candidate
      ? candidate
      : getInitialCreateCandidate(
          firstNoConflictingBooking.vehicleType,
          firstNoConflictingBooking.height,
          firstNoConflictingBooking.length,
          firstNoConflictingBooking.width,
          firstNoConflictingBooking.tradeWeight,
        ),
  );

  const defaultCandidates = fill(
    Array(numberOfCandidates - candidates.length),
    getInitialCreateCandidate(
      firstNoConflictingBooking.vehicleType,
      firstNoConflictingBooking.height,
      firstNoConflictingBooking.length,
      firstNoConflictingBooking.width,
      firstNoConflictingBooking.tradeWeight,
    ),
  );

  return candidates.concat(defaultCandidates);
};

export const transformBookingCandidates = (formState: CreateFormState): CreateBookingInput[] => {
  return formState.bookings.map(transformCandidate(formState));
};

export const transformCandidate =
  (formState: CreateFormState) =>
  (candidate: CreateCandidate): CreateBookingInput => {
    const fieldsToShow = getCreateFormFieldsToShow(formState);

    if (!formState.sailing) {
      throw new Error('No sailing selected');
    }

    if (!candidate.vehicleType) {
      throw new Error('No vehicle type selected');
    }

    return {
      importReference: candidate.importReference || undefined,
      exportReference: candidate.exportReference || undefined,
      cargoWeight:
        isVisible(fieldsToShow, ColumnId.CargoWeight) && candidate.hasCargoWeight
          ? candidate.cargoWeight
            ? candidate.cargoWeight
            : 0
          : 0,
      custNo:
        isVisible(fieldsToShow, ColumnId.CustNo) && formState.customer
          ? formState.customer.custNo
          : 0,
      customerReference: isVisible(fieldsToShow, ColumnId.CustomerReference)
        ? candidate.customerRef || ''
        : '',
      customsClassificationCode:
        isVisible(fieldsToShow, ColumnId.CustomsClassification) &&
        candidate.customsClassification &&
        candidate.customsClassification !== customsClassificationDropdownEmptyOption.data
          ? candidate.customsClassification.value
          : '',
      hazardousGoods:
        isVisible(fieldsToShow, ColumnId.HazardousGoods) && candidate.hasHazardousGoods,
      height: isVisible(fieldsToShow, ColumnId.Height) ? candidate.height || 0 : 0,
      length: isVisible(fieldsToShow, ColumnId.Length) ? candidate.length || 0 : 0,
      livestockTypeCode:
        isVisible(fieldsToShow, ColumnId.LivestockTypeCode) &&
        candidate.hasAnimals &&
        candidate.livestockType !== animalDropdownNoOption.data
          ? candidate.livestockType.value
          : '',
      loadingListMessage: isVisible(fieldsToShow, ColumnId.LoadingListMessage)
        ? candidate.loadingListMessage || ''
        : '',
      loadingPreferenceCode:
        isVisible(fieldsToShow, ColumnId.LoadingPreferenceCode) &&
        candidate.loadingPreference !== loadingPreferenceDropdownNoOption.data
          ? candidate.loadingPreference.value
          : '',
      noOfAdults: 0,
      noOfChildren: 0,
      noOfDrivers: isVisible(fieldsToShow, ColumnId.NoOfDrivers)
        ? candidate.numberOfDrivers || 0
        : 0,
      noOfInfants: 0,
      noOfPlugins:
        isVisible(fieldsToShow, ColumnId.Temperature) && candidate.hasElectricalPlugin ? 1 : 0,
      pluginTemperature:
        isVisible(fieldsToShow, ColumnId.Temperature) && candidate.hasElectricalPlugin
          ? candidate.pluginTemperature || ''
          : '',
      sailingId:
        isVisible(fieldsToShow, ColumnId.DepartureTime) && formState.sailing
          ? formState.sailing.id
          : '',
      tradeWeight: isVisible(fieldsToShow, ColumnId.TradeWeight) ? candidate.tradeWeight || 0 : 0,
      trailerRegNo: formatRegistrationNumberString(candidate.trailerReg ?? ''),
      vehicleRegNo: formatRegistrationNumberString(candidate.vehicleReg ?? ''),
      vehicleTypeCode:
        isVisible(fieldsToShow, ColumnId.VehicleTypeCode) && candidate.vehicleType
          ? candidate.vehicleType.code
          : '',
      width: isVisible(fieldsToShow, ColumnId.Width) ? candidate.width || 0 : 0,
      senderCountryCode:
        isVisible(fieldsToShow, ColumnId.SenderCountryCode) &&
        candidate.cargoWeight &&
        candidate.cargoWeight > 0 &&
        candidate.senderCountry
          ? candidate.senderCountry.value
          : '',
      receiverCountryCode:
        isVisible(fieldsToShow, ColumnId.ReceiverCountryCode) &&
        candidate.cargoWeight &&
        candidate.cargoWeight > 0 &&
        candidate.receiverCountry
          ? candidate.receiverCountry.value
          : '',
    };
  };

export const findCandidateWithoutRegNumberAlert = (
  bookings: CreateCandidate[],
): CreateCandidate | undefined => {
  return bookings.find(booking => booking.registrationNumberAlert === null);
};

export const getCreateCandidate = (candidates: CreateCandidate[], index: number): CreateCandidate =>
  candidates[index] || createCreateCandidateMock();

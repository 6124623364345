import { Box } from '@stenajs-webui/core';
import { FC } from 'react';
import { Sailing } from '../../common/graphql/fragments/gql/Sailing';
import { useLocalDateFormat } from '../../common/hooks/useLocalDateFormat';
import { getShortArrivalInformation } from './utils/getExtendedArrivalInformation';
import { TripDetails } from '../TripDetails';
import { breakpoints } from '../../themes/defaultTheme';
import styled from '@emotion/styled';
import { PortDetails } from '../PortDetails';
import { BookingStatus } from '../../gql/graphql';

const Wrapper = styled(Box)`
  justify-content: center;

  @media print, screen and (min-width: ${breakpoints.md}) {
    justify-content: start;
    flex-direction: row;
  }
`;

export const DetailsBox = styled(Box)`
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-auto-flow: column;
  gap: var(--swui-metrics-space) calc(2 * var(--swui-metrics-space));
`;

export interface SailingInfoHorizontalProps {
  hasArrived?: boolean;
  releasedAt?: string | null;
  sailing: Sailing;
  bookingStatus?: BookingStatus | null;
}

export const SailingInfoHorizontal: FC<SailingInfoHorizontalProps> = ({
  hasArrived = false,
  releasedAt,
  sailing,
  bookingStatus,
}) => {
  const dateFormat = useLocalDateFormat();
  const arrivalInformation = sailing
    ? getShortArrivalInformation(hasArrived, sailing, dateFormat)
    : null;

  return (
    <Wrapper width={'100%'} gap={[3, 3, 5]} maxWidth={900}>
      <PortDetails
        departureLocation={sailing.route.departureLocation.name}
        arrivalLocation={sailing.route.arrivalLocation.name}
        departureDate={sailing.departureDate}
        departureTime={sailing.departureTime}
        arrivalDate={sailing.arrivalDate}
        arrivalTime={sailing.arrivalTime}
        bookingStatus={bookingStatus}
      />
      <DetailsBox>
        <TripDetails
          releasedAt={releasedAt}
          arrivalInformation={arrivalInformation}
          sailingType={sailing.sailingType}
          shipName={sailing.shipName}
          dateFormat={dateFormat}
        />
      </DetailsBox>
    </Wrapper>
  );
};

import { resolveAnimalsInformationText } from '../../../../common/utils/animalsUtils';
import { resolveArrivalNotepadApprovalInfo } from '../utils/arrivalNotepadInformation';
import {
  getHazardousGoodsApprovalInfo,
  getLoadingPreferenceApprovalInfo,
} from '../utils/detailsUtils';
import { BookingInformationMessages } from './BookingInformationMessages';
import { shouldShowHazGoodsPaymentInformation } from '../utils/showPaymentUtil';
import { DetailedBooking } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { UpdateSubmitInformation } from '../../../../common/update-information/utils/getUpdateSubmitInformation';
import { resolveAutoCancellationInformation } from '../utils/autoCancellationUtils';

interface InfoMessageDeciderProps {
  booking: DetailedBooking;
  updateSubmitInformation: UpdateSubmitInformation;
}

export const InfoMessageDecider = ({
  booking,
  updateSubmitInformation,
}: InfoMessageDeciderProps) => {
  const loadingPreferenceApprovalInfo = getLoadingPreferenceApprovalInfo(
    booking.loadingPreference,
    booking.loadingPreferenceStatusCode,
  );
  const hazardousGoodsApprovalInfo = getHazardousGoodsApprovalInfo(
    booking.hazardousGoods,
    booking.hazardousGoodsApproved,
    booking.bookingStatus.status,
  );
  const livestockInfoText = resolveAnimalsInformationText(booking.livestock);
  const arrivalNotepadInfo = resolveArrivalNotepadApprovalInfo(booking.arrivalNotepadApplied);
  const showHazGoodsPaymentInformation = shouldShowHazGoodsPaymentInformation(booking);
  const autoCancellation = resolveAutoCancellationInformation(
    updateSubmitInformation,
    booking.autoCancellation,
  );

  if (
    !loadingPreferenceApprovalInfo &&
    !hazardousGoodsApprovalInfo &&
    !livestockInfoText &&
    !arrivalNotepadInfo &&
    !autoCancellation
  ) {
    return null;
  }

  return (
    <BookingInformationMessages
      arrivalNotepadInfo={arrivalNotepadInfo}
      autoCancellation={autoCancellation}
      bookingNo={booking.bookingNo}
      hazardousGoodsApproved={booking.hazardousGoodsApproved}
      hazardousGoodsApprovalInfo={hazardousGoodsApprovalInfo}
      livestockInfoText={livestockInfoText}
      loadingPreferenceApprovalInfo={loadingPreferenceApprovalInfo}
      showHazGoodsPaymentInformation={showHazGoodsPaymentInformation}
    />
  );
};

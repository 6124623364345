import { TagVariant } from '@stenajs-webui/elements';
import { Sailing_sailingType } from '../../common/graphql/fragments/gql/Sailing';

export function resolveTagVariant(sailingType: Sailing_sailingType | undefined): TagVariant {
  switch (sailingType?.label) {
    case 'Peak':
      return 'warning';
    case 'Offpeak':
      return 'success';
    case 'Shoulder':
      return 'info';
    default:
      return 'passive';
  }
}

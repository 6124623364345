import { Box } from '@stenajs-webui/core';
import { Banner } from '@stenajs-webui/elements';
import { FC } from 'react';
import {
  InformationMessageType,
  resolveInformationMessageBannerVariant,
} from '../../../../common-ui/information-message/informationMessageUtils';
import { FlatButtonLink } from '../../../../common-ui/Link';
import { routeCreators } from '../../../../common/routes/AppRoutes';
import { ApprovalConfig } from '../utils/approvalInfo';
import { DetailedBooking_autoCancellation } from '../../../../common/graphql/fragments/gql/DetailedBooking';
import { AutoCancellationInfoBanner } from './AutoCancellationInfoBanner';

interface BookingInformationMessagesProps {
  arrivalNotepadInfo: ApprovalConfig | null;
  bookingNo: number;
  hazardousGoodsApprovalInfo: ApprovalConfig | null;
  hazardousGoodsApproved: boolean;
  loadingPreferenceApprovalInfo: ApprovalConfig | null;
  autoCancellation: DetailedBooking_autoCancellation | null;
  livestockInfoText: string | undefined;
  showHazGoodsPaymentInformation: boolean;
}

export const BookingInformationMessages: FC<BookingInformationMessagesProps> = ({
  arrivalNotepadInfo,
  bookingNo,
  hazardousGoodsApprovalInfo,
  hazardousGoodsApproved,
  loadingPreferenceApprovalInfo,
  livestockInfoText,
  showHazGoodsPaymentInformation,
  autoCancellation,
}) => {
  return (
    <Box spacing gap>
      {autoCancellation && <AutoCancellationInfoBanner info={autoCancellation} />}
      {hazardousGoodsApprovalInfo && (
        <>
          <Banner
            variant={resolveInformationMessageBannerVariant(hazardousGoodsApprovalInfo.type)}
            headerText={'Hazardous goods'}
            text={hazardousGoodsApprovalInfo.descriptionText}
            contentRight={
              hazardousGoodsApproved && (
                <FlatButtonLink
                  to={routeCreators.hazardousGoods({ bookingNo })}
                  label={'See documents'}
                />
              )
            }
          />
          {showHazGoodsPaymentInformation && (
            <Banner
              variant={'info'}
              headerText={'Booking cannot be paid yet'}
              text={'Once the hazardous goods are confirmed the booking can be paid online.'}
            />
          )}
        </>
      )}

      {loadingPreferenceApprovalInfo && (
        <Banner
          variant={resolveInformationMessageBannerVariant(loadingPreferenceApprovalInfo.type)}
          headerText={'Loading pref.'}
          text={loadingPreferenceApprovalInfo.descriptionText}
        />
      )}

      {arrivalNotepadInfo && (
        <Banner
          variant={resolveInformationMessageBannerVariant(arrivalNotepadInfo.type)}
          headerText={arrivalNotepadInfo.headerText}
          text={arrivalNotepadInfo.descriptionText}
        />
      )}

      {livestockInfoText && (
        <Banner
          variant={resolveInformationMessageBannerVariant(InformationMessageType.SoftWarning)}
          headerText={'Animals'}
          text={livestockInfoText}
        />
      )}
    </Box>
  );
};
